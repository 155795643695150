import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ProcessedQuote } from "../../domain/models";
import { useAdminModelController } from "../hook/useAdminModelController";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { formatItalianDate } from "../../utils/dateUtils";
import { AuthRepository } from "../../domain/repository/AuthRepository";
import PaginationComponent from "../components/PaginationComponent";

export function SendedQuotesView({
  authRepository,
  productRepository,
  quotesRepository,
}: {
  authRepository: AuthRepository;
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();

  const [sendedQuotes, setSendedQuotes] = useState<ProcessedQuote[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const { handleGetAllProcessedQuotes } = useAdminModelController(
    productRepository,
    quotesRepository,
  );

  useEffect(() => {
    loadQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadQuotes = async () => {
    const processedQuotes = await handleGetAllProcessedQuotes();
    console.log(processedQuotes);
    setSendedQuotes(processedQuotes);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const navigateToQuote = (quote: ProcessedQuote) => {
    navigate(`/dashboard/sendedQuotes/${quote.id}`, { state: { quote } });
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentQuotes = sendedQuotes.slice(startIndex, endIndex);

  return (
    <div className="min-h-full w-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <main className="mx-auto w-full px-6 md:px-24">
        <div className="my-4 justify-between border-b border-gray-200 md:my-10">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Preventivi inviati
          </h1>
        </div>
        <ul className="divide-y divide-gray-100">
          {currentQuotes.map((quote) => (
            <li
              key={quote.id}
              className="flex cursor-pointer justify-between gap-x-6 px-4 py-5 transition-all duration-300 hover:bg-palette-light"
              onClick={() => navigateToQuote(quote)}
            >
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {quote.toName}
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {quote.text}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">Inviato</p>
                  <p className="mt-1 text-xs leading-5 text-gray-500">
                    <time dateTime={quote.date}>
                      {formatItalianDate(quote.date)}
                    </time>
                  </p>
                </div>
                <div className="ml-4">
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </div>
              </div>
            </li>
          ))}
        </ul>

        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <PaginationComponent
            currentPage={currentPage}
            totalPages={Math.ceil(sendedQuotes.length / itemsPerPage)}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            totalItems={sendedQuotes.length}
          />
        </div>
      </main>
      <FooterComponent />
    </div>
  );
}
