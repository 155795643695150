import { DocumentData, QuerySnapshot } from "firebase/firestore";
import {
  PendingQuote,
  ProcessedQuote,
  QuoteRequest,
  Result,
} from "../../domain/models";
import { Success, Failure } from "../../domain/models/Result";
import { ResponseSuccess } from "../models/ApiResponse";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { QuotesDatasource } from "../datasource/QuotesDatasource";
import { NewProcessedQuote } from "../../domain/models/NewProcessedQuote";
import { FirebaseEmail } from "../../domain/models/FirebaseEmail";

export class QuotesRepositoryImpl implements QuotesRepository {
  dataSource: QuotesDatasource;
  constructor(dataSource: QuotesDatasource) {
    this.dataSource = dataSource;
  }

  async createQuoteRequest(
    quoteRequest: QuoteRequest
  ): Promise<Result<boolean>> {
    const quoteResponse = await this.dataSource.createQuoteRequest(
      quoteRequest
    );
    if (quoteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (quoteResponse as Failure<string>).error;
      return new Failure(error);
    }
  }
  async getPendingQuotes(): Promise<Result<PendingQuote[]>> {
    const pendingQuotes: PendingQuote[] = [];
    const pendingQuotesResponse = await this.dataSource.getPendingQuotes();
    if (pendingQuotesResponse instanceof ResponseSuccess) {
      const snapshot: QuerySnapshot<DocumentData, DocumentData> =
        pendingQuotesResponse.value;
      snapshot.forEach((doc) => {
        const quoteData = doc.data();
        const pendingQuote: PendingQuote = {
          id: doc.id,
          name: quoteData.name,
          email: quoteData.email,
          phone: quoteData.phone,
          pIVA: quoteData.pIVA ?? "",
          codUnivoco: quoteData.codUnivoco ?? "",
          codFiscale: quoteData.codFiscale ?? "",
          address: quoteData.address,
          city: quoteData.city,
          cap: quoteData.cap,
          date: quoteData.date,
          cart: quoteData.cart,
          shippingAddress: quoteData.shippingAddress,
          shippingCity: quoteData.shippingCity,
          shippingCap: quoteData.shippingCap,
          deliveryDate: quoteData.deliveryDate,
          deliveryTime: quoteData.deliveryTime ?? "",
          eventDate: quoteData.eventDate,
          returnDate: quoteData.returnDate,
          returnTime: quoteData.returnTime ?? "",
          localPickup: quoteData.localPickup ?? false,
        };
        pendingQuotes.push(pendingQuote);
      });
      return new Success(pendingQuotes);
    } else {
      const error: string = (pendingQuotesResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async getProcessedQuotes(): Promise<Result<PendingQuote[]>> {
    const processedQuotes: ProcessedQuote[] = [];
    const processedQuotesResponse = await this.dataSource.getProcessedQuotes();
    if (processedQuotesResponse instanceof ResponseSuccess) {
      const snapshot: QuerySnapshot<DocumentData, DocumentData> =
        processedQuotesResponse.value;
      snapshot.forEach((doc) => {
        const quoteData = doc.data();
        const processedQuote: ProcessedQuote = {
          id: doc.id,
          toEmail: quoteData.toEmail,
          toName: quoteData.toName,
          phone: quoteData.phone,
          pIVA: quoteData.pIVA ?? "",
          codUnivoco: quoteData.codUnivoco ?? "",
          codFiscale: quoteData.codFiscale ?? "",
          text: quoteData.text,
          date: quoteData.date,
          cart: quoteData.cart,
          shippingPrice: quoteData.shippingPrice,
          shippingAddress: quoteData.shippingAddress,
          shippingCity: quoteData.shippingCity,
          shippingCap: quoteData.shippingCap,
          deliveryDate: quoteData.deliveryDate,
          deliveryTime: quoteData.deliveryTime ?? "",
          eventDate: quoteData.eventDate,
          returnDate: quoteData.returnDate,
          returnTime: quoteData.returnTime ?? "",
          localPickup: quoteData.localPickup ?? false,
          totalPrice: quoteData.totalPrice,
          pdfUrl: quoteData.pdfUrl,
        };
        processedQuotes.push(processedQuote);
      });
      console.log(processedQuotes);
      return new Success(processedQuotes);
    } else {
      const error: string = (processedQuotesResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async processPendingQuote(
    pendingQuoteId: string,
    newProcessedQuote: NewProcessedQuote,
    firebaseEmail: FirebaseEmail
  ): Promise<Result<boolean>> {
    const quoteResponse = await this.dataSource.addProcessedQuote(
      pendingQuoteId,
      newProcessedQuote,
      firebaseEmail
    );
    if (quoteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (quoteResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async uploadPDFQuote(
    clientEmail: string,
    pdf: File,
    fileName: string
  ): Promise<Result<string>> {
    const pdfResponse = await this.dataSource.uploadPDFQuote(
      clientEmail,
      pdf,
      fileName
    );
    if (pdfResponse instanceof ResponseSuccess) {
      return new Success(pdfResponse.value);
    } else {
      const error: string = (pdfResponse as Failure<string>).error;
      return new Failure(error);
    }
  }

  async deletePendingQuote(pendingQuoteId: string): Promise<Result<boolean>> {
    const quoteResponse = await this.dataSource.deletePendingQuote(
      pendingQuoteId
    );
    if (quoteResponse instanceof ResponseSuccess) {
      return new Success(true);
    } else {
      const error: string = (quoteResponse as Failure<string>).error;
      return new Failure(error);
    }
  }
}
