import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { PendingQuote } from "../../domain/models";
import { useAdminModelController } from "../hook/useAdminModelController";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { getFormattedDate } from "../../utils/dateUtils";
import { AuthRepository } from "../../domain/repository/AuthRepository";
import PaginationComponent from "../components/PaginationComponent";

export function PendingQuotesView({
  authRepository,
  productRepository,
  quotesRepository,
}: {
  authRepository: AuthRepository;
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();

  const [pendingQuotes, setPendingQuotes] = useState<PendingQuote[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { handleGetAllPendingQuotes } = useAdminModelController(
    productRepository,
    quotesRepository,
  );
  const itemsPerPage = 5;

  useEffect(() => {
    loadPendingQuotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const loadPendingQuotes = async () => {
    const pendingQuotesResponse = await handleGetAllPendingQuotes();
    console.log(pendingQuotesResponse);
    setPendingQuotes(pendingQuotesResponse);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const navigateToQuote = (quote: PendingQuote) => {
    navigate(`/dashboard/pendingQuotes/${quote.id}`, { state: { quote } });
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentQuotes = pendingQuotes.slice(startIndex, endIndex);

  return (
    <div className="min-h-full w-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <main className="mx-auto w-full px-6 md:px-24">
        <div className="my-4 justify-between border-b border-gray-200 md:my-10">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Preventivi in attesa
          </h1>
        </div>
        <ul className="divide-y divide-gray-100">
          {currentQuotes &&
            currentQuotes.map((quote) => (
              <li
                key={quote.id}
                className="flex cursor-pointer justify-between gap-x-6 px-4 py-5 transition-all duration-300 hover:bg-palette-light"
                onClick={() => navigateToQuote(quote)}
              >
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {quote.name}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {quote.email}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900">In attesa</p>
                    <p className="mt-1 text-xs leading-5 text-gray-500">
                      <span>{getFormattedDate(quote.date)}</span>
                    </p>
                  </div>
                  <div className="ml-4">
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                </div>
              </li>
            ))}
        </ul>

        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              type="button"
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              type="button"
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <PaginationComponent
              currentPage={currentPage}
              totalPages={Math.ceil(pendingQuotes.length / itemsPerPage)}
              onPageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              totalItems={pendingQuotes.length}
            />
          </div>
        </div>
      </main>
      <FooterComponent />
    </div>
  );
}
