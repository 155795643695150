import { useLocation } from "react-router-dom";
import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { ProcessedQuote } from "../../domain/models/ProcessedQuote";
import { formatItalianDate, getFormattedDate } from "../../utils/dateUtils";
import { ProductCart } from "../../domain/models/ProductCart";
import { AuthRepository } from "../../domain/repository/AuthRepository";

export default function SendedQuoteDetailView({
  authRepository,
}: {
  authRepository: AuthRepository;
}) {
  const location = useLocation();
  const quote: ProcessedQuote = location.state.quote;

  return (
    <div className="min-h-full w-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <main className="mx-auto w-full px-6 md:px-24">
        <div className="my-4 justify-between border-b border-gray-200 md:my-10">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Dettaglio preventivo
          </h1>
          <p className="mt-5 text-sm leading-5 text-gray-500">
            <time dateTime={quote.date}>{formatItalianDate(quote.date)}</time>
          </p>
          <h3 className="mt-2">
            <span className="font-bold">Cliente:</span> {quote.toName}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Email:</span> {quote.toEmail}
          </h3>
          {quote.codFiscale === "" ? (
            <div>
              <h3 className="mt-2">
                <span className="font-bold">Partita Iva:</span>
                {quote.pIVA !== "" ? quote.pIVA : " non specificata"}
              </h3>
              <h3 className="mt-2">
                <span className="font-bold">Codice univoco:</span>
                {quote.codUnivoco !== ""
                  ? quote.codUnivoco
                  : " non specificato"}
              </h3>
            </div>
          ) : (
            <h3 className="mt-2">
              <span className="font-bold">Codice fiscale:</span>
              {quote.codFiscale !== "" ? quote.codFiscale : " non specificato"}
            </h3>
          )}
          <h3 className="mt-2">
            <span className="font-bold">Indirizzo di spedizione:</span>{" "}
            {quote.shippingAddress} {quote.shippingCity} {quote.shippingCap}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data consegna:</span>{" "}
            {getFormattedDate(quote.deliveryDate)}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data evento:</span>{" "}
            {getFormattedDate(quote.eventDate)}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data ritiro:</span>{" "}
            {getFormattedDate(quote.returnDate)}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Ritiro in loco:</span>{" "}
            {quote.localPickup ? "Si" : "No"}
          </h3>
          <h2 className="mt-10 font-bold">Testo del preventivo</h2>
          <p className="mt-4">{quote.text}</p>
          <h2 className="mt-10 font-bold">Preventivo</h2>
          <a
            className="mt-4 text-blue-500"
            href={quote.pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Visualizza PDF
          </a>
          <label
            htmlFor="description"
            className="mt-10 block text-sm font-bold leading-6 text-gray-900"
          >
            Carrello
          </label>
          <div
            className="mt-6 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {quote.cart.length > 0 ? (
              quote.cart.map((product: ProductCart) => (
                <div
                  className="flex flex-col rounded-lg bg-white sm:flex-row"
                  key={product.id}
                >
                  <img
                    className="m-2 h-24 w-28 rounded-md border object-contain object-center"
                    src={product.image}
                    alt="product"
                  />
                  <div className="flex w-full flex-col px-4 py-4">
                    <span className="font-semibold">{product.title}</span>
                    <span className="float-right text-gray-400">
                      Quantità: {product.quantity}
                    </span>
                    <div className="flex space-x-3">
                      <p className="text-lg font-bold">{product.price}€</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="font-semibold">
                Non hai ancora aggiunto nessun prodotto al carrello
              </p>
            )}
          </div>
          <h3 className="mb-2 mt-6">
            <span className="text-lg font-bold">Costi di spedizione:</span>{" "}
            {quote.shippingPrice.toFixed(2).replace(".", ",")}€
          </h3>
          <span className="text-lg font-bold">Totale preventivo:</span>{" "}
          {quote.totalPrice.toFixed(2).replace(".", ",") ?? 0}€
          <div className="mt-10"></div>
        </div>
      </main>
      <FooterComponent />
    </div>
  );
}
