import { useLocation, useNavigate } from "react-router-dom";
import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { PendingQuote } from "../../domain/models/PendingQuote";
import { ProductCart } from "../../domain/models/ProductCart";
import { useState } from "react";
import { NewProcessedQuote } from "../../domain/models/NewProcessedQuote";
import { faFloppyDisk, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { useAdminModelController } from "../hook/useAdminModelController";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import {
  getFormattedDate,
  getFormattedDateWithHours,
} from "../../utils/dateUtils";
import { AuthRepository } from "../../domain/repository/AuthRepository";
import { FirebaseEmail } from "../../domain/models/FirebaseEmail";
import { LoaderView } from "../components/LoaderView";
import ConfirmDialogComponent, {
  DialogType,
} from "../components/admin/ConfirmDialogComponent";
import logo from "../../assets/images/logo-dap-rent.png";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  pdf,
  Font,
} from "@react-pdf/renderer";

export enum DiscountType {
  Percentage,
  Amount,
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  headerSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 20,
  },
  companyInfo: {
    flex: 1,
    paddingRight: 10,
  },
  companyName: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  companyData: {
    fontSize: 10,
    color: "#7d7d7d",
    marginBottom: 2,
  },
  li: { fontSize: 8, color: "#000", marginBottom: 10 },
  clientRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  clientInfoContainer: {
    borderWidth: 1,
    borderColor: "#e0e0e0",
    borderStyle: "solid",
    padding: 5,
    marginBottom: 10,
    alignSelf: "flex-start",
    flex: 1,
    marginRight: 10,
  },
  eventInfoContainer: {
    borderWidth: 1,
    borderColor: "#e0e0e0",
    borderStyle: "solid",
    padding: 5,
    marginBottom: 10,
    alignSelf: "flex-start",
    flex: 1,
  },
  tableHeader: {
    marginTop: 30,
    flexDirection: "row",
    backgroundColor: "#e0e0e0",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    padding: 5,
    alignItems: "center",
    textAlign: "center",
  },
  tableRow: {
    flexDirection: "row",
    marginTop: 2,
    paddingBottom: 2,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
    borderBottomStyle: "solid",
    alignItems: "center",
    textAlign: "center",
  },
  tableCol: {
    flex: 1,
    paddingRight: 10,
    fontSize: 12,
  },
  imageCol: {
    height: 40,
    objectFit: "contain",
  },
  textRight: {
    textAlign: "right",
  },
  summaryPriceSection: {
    borderWidth: 1,
    borderColor: "#e0e0e0",
    borderStyle: "solid",
    marginTop: 50,
    marginLeft: "auto",
    width: "40%",
    textAlign: "right",
  },

  productTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 16,
  },
  productDescription: {
    fontFamily: "Open Sans",
    fontSize: 14,
  },
  title: {
    fontFamily: "Open Sans",
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  quoteNumber: {
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 20,
  },
  sectionTitle: {
    fontFamily: "Open Sans",
    fontSize: 18,
    fontWeight: "bold",
    marginVertical: 10,
  },
  productList: {
    fontSize: 12,
    marginVertical: 10,
  },
  productItem: {
    marginVertical: 10,
  },
  productImage: {
    width: 150,
    height: 150,
    marginTop: 30,
    marginBottom: 10,
    objectFit: "contain",
  },
  summary: {
    fontSize: 14,
    textAlign: "right",
    marginVertical: 5,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },

  summaryPriceContainer: {
    textAlign: "right",
    marginVertical: 5,
    marginRight: 5,
  },
  summaryLabel: {
    fontSize: 10,
    color: "#7d7d7d",
  },
  summaryValue: {
    fontSize: 12,
    color: "#000",
    fontWeight: "bold",
  },
  paymentInfo: {
    fontSize: 12,
    marginTop: 20,
    textAlign: "left",
  },
  footer: {
    fontSize: 10,
    marginTop: 100,
    marginBottom: 20,
    textAlign: "center",
  },
  logoHeader: {
    height: 70,
    alignItems: "flex-start",
  },
  logo: {
    width: 100,
    marginVertical: 20,
    alignSelf: "center",
  },
});

const contractItems = [
  "Contratto di noleggio. Il contratto è un accordo tra la scrivente Società EVENTI & SAPORI srl, quale Fornitore del materiale come indicato nell'Ordine di noleggio, e la persona fisica e/o giuridica ivi indicata, quale Committente del medesimo. Al momento dell'ordine il Committente dovrà comunicare l'eventuale presenza di zone pedonali e/o traffico limitato (ZTL) ed i relativi permessi necessari per effettuare l'accesso alla zona di scarico. Dovranno inoltre essere comunicate eventuali difficoltà d'accesso sia per l'entrata dei mezzi che per lo scarico del materiale nel luogo di destinazione. In assenza di tali comunicazioni, qualora dovessero presentarsi le circostanze sopracitate, lo scarico verrà effettuato nel luogo più vicino raggiungibile dai nostri automezzi a quello indicato per evitare infrazioni e/o danneggiamenti a mezzi e strutture.",
  "Requisiti minimi di noleggio. che variano a seconda della distanza, del numero di mezzi utilizzati e del personale necessario per lo scarico. Lo scarico verrà effettuato dal nostro personale al piano terra del luogo della consegna più vicino raggiungibile con i nostri automezzi; eventuali diverse situazioni dovranno essere comunicate al momento dell'ordine al fine di valutare eventuali servizi di facchinaggio. Il materiale verrà consegnato pulito, impacchettato e sterilizzato, negli appositi contenitori.",
  "Consegna e scarico del materiale . Il materiale può essere consegnato a destinazione indicata dal destinatario con spese di viaggio nel Documento di trasporto. Il Fornitore al fine di garantire il materiale noleggiato da eventuali rotture ed ammanchi richiederà la garanzia di una carta di credito.",
  "Garanzia e responsabilità del Committente. Dal momento della consegna il Committente si farà garante del materiale consegnato indicato nel documento di trasporto. Il fornitore al fine di garantire il materiale noleggiato da eventuali rotture ed ammacchi richiederà la garanzia di una carta di credito.",
  "Riconsegna del materiale. La riconsegna avverrà nel luogo e con le tempistiche indicate al momento dell'ordine. A tal proposito si precisa che le scheggiature del materiale frangibile sono da intendersi come rotture. Il materiale verrà poi ritirato sporco. Il materiale dovrà essere riconsegnato negli appositi contenitori ognuno dei quali dovrà contenere il numero esatto di pezzi riportato in ogni contenitore. Tutto il materiale dovrà inoltre essere ricollocato nella parte più comoda per il ritiro. Il lavaggio del materiale è da intendersi a nostro carico, ed il conteggio del materiale minuto verrà eseguito nei nostri magazzini una volta pulito il materiale. Eventuale materiale mancante o danneggiato sarà a carico del committente e quantificato al costo di listino rotture e mancanze.  Il Commitente è tenuto a riconsegnare il materiale noleggiato nella data e luogo concordata al momento dell'ordine. Se il Committente ha necessità di riconsegnare il materiale oltre l'orario/data stabiliti sarà tenuto a richiedere per iscritto il prolungamento. Eventuale prolungamento del noleggio non concordati sarà addebitato un ulteriore noleggio in base al tempo trattenuto.",
  "Contestazioni Eventuali. contestazioni sul materiale consegnato dovranno essere evidenziate lo stesso giorno della consegna ed in tal caso verrà effettuata la sostituzione senza alcun costo aggiuntivo. Per le contestazioni pervenute nei giorni successivi verranno addebitate le spese di consegna.",
  "Pagamento. Il pagamento integrale dell'ordine dovrà esser effettuato dal Committente prima del ricevimento del materiale noleggiato con le modalità definite al momento dell’ordine",
  "Pre-autorizzazione e garanzia. Alla sottoscrizione del contratto di noleggio il Cliente concede al Fornitore il diritto di bloccare un importo a garanzia sulla propria carta di credito in proporzione al valore totale dell'ordine, vincolando così il totale importo sulla carta di credito per garantire la presenza di fondi sufficienti per garantire eventuali ammanchi o rotture del materiale noleggiato. Se il Cliente pagherà con un altra carta, saranno necessari fino ad un massimo di 28 giorni prima che la banca restituisca l'importo precedentemente 'bloccato'. Sottoscrivendo il contratto di noleggio, il Cliente autorizza la Società EVENTI & SAPORI Srl ad addebitare sulla carta di pagamento tutti i costi generati da eventuali rotture e/o mancanze e/o danneggiamenti del materiale noleggiato.",
  "Caparra confirmatoria. Il Committente al momento dell'ordine si impegna a corrispondere al fornitore una somma a titolo di caparra confirmatoria pari al 50% dell'ordine, pena la risoluzione dell'accordo, per ordini di importo ridotto può essere richiesto anche il 100%.",
  "Avvertenza. Si precisa che la disponibilità e la consegna del materiale non sono garantite fino all'accettazione del preventivo/offerta inviato dal Fornitore ed alla conseguente ricezione del contratto debitamente firmato in ogni sua parte dal Committente. Solo alla RICEZIONE del contratto firmato verranno verificate le effettive disponibilità del materiale richiesto e la possibilità di effettuare la consegna. Si ricorda che il preventivo/offerta è puramente indicativo e non comporta alcun vincolo d'opzione sul materiale, nè alcun obbligo a carico del Fornitore e del Cliente.",
  "Clausola Risolutiva. La violazione anche di una sola delle disposizioni degli articoli 2, 3, 4, 5, 6, 8 e 9 legittimerà il Fornitore alla effettuare risoluzione del contratto ai sensi dell'art. 1456 del codice civile e al risarcimento dei danni.",
  "Clausola Pandemica. Qualora in caso o a causa della diffusione del Covid-19 o altre variazioni dello stesso fosse impossibile viaggiare e/o effettuare il noleggio nella data concordata, Eventi & Sapori srl darà la possibilità ai propri clienti di utilizzare il deposito cauzionale versato per ulteriore ordine che dovrà avvenire entro e non noltre i 12 mesi dalla data inizialmente concordata, pena la perdita della somma corrisposta a titolo di caparra confirmatoria.",
  "Caparra Penitenziale. In caso di cancellazione, recesso, risoluzione e/o atto e/o dichiarazione interruttiva dell'ordine di noleggio comunicata al Cliente il Fornitore tratterrà l'intera caparra data. In caso di cancellazione, recesso , risoluzione e/o atto e/o dichiarazione interruttiva dell'ordine a meno di 7 giorni dalla consegna del materiale il Fornitore avrà diritto al pagamento dell'intero corrispettivo di noleggio.",
  "Variazioni dell'ordine. Nel caso in cui il Cliente dovesse variare la data dell'ordine, il Fornitore si avvale della facoltà di controllare la disponibilità del materiale e di adeguare i prezzi degli articoli ai listini in corso.",
  "Legge applicabile e Foro Competente Il contratto di noleggio sottoscritto tra le parti sarà disciplinato dalla legge Italiana e dal Codice Civile. Per ogni controversia derivante dai rapporti regolati da Ordine e Condizioni generali di noleggio, è competente in via esclusiva il Foro di Torino",
  "Tutela della riservatezza e trattamento dei dati personali. Ai sensi dell'art.12 della Leg. 196/2003 (Testo Unico sulla Privacy e successive modifiche ed integrazioni GDPR Regolamento Generale sulla Protezione dei Dati - Regolamento 2016/679), i dati personali che riguardano il Committente saranno trattati dalla Società EVENTI & SAPORI Srl per l'adempimento dell'ordine di noleggio e non verranno comunicati a terzi. I dati possono esser utilizzati per informare sulle attività della Società. In relazione ai dati forniti il conferente può esercitare i diritti espressi all'art. 6 della Leg. 196/2003 (Testo Unico sulla Privacy e successive modifiche e integrazioni GDPR Regolamento Generale sulla Protezione dei Dati - Regolamento 2016/679): conferma dell'esistenza dei dati , origine finalità aggiornamenti, cancellazione, diritto di opposizione. Per esercitare tali diritti dovrà fare riferimento al responsabile del trattamento dei dati della Società EVENTI & SAPORI Srl. Le Parti si autorizzano reciprocamente a comunicare a terzi i rispettivi dati personali in relazione agli obblighi connessi al contratto di noleggio (ex D.lgs 196/2003).",
];

export default function PendingQuoteDetailView({
  authRepository,
  productRepository,
  quotesRepository,
}: {
  authRepository: AuthRepository;
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const quote: PendingQuote = location.state.quote;

  const [newPrices, setNewPrices] = useState<ProductCart[]>(quote.cart);
  const [description, setDescription] = useState<string>("");
  const [shippingCost, setShippingCost] = useState<string>("0");
  const [discountValue, setDiscountValue] = useState<string>("0");
  const [discountType, setDiscountType] = useState<DiscountType>(
    DiscountType.Percentage,
  );
  const [editPrice, setEditPrice] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] =
    useState<boolean>(false);
  const [confirmQuoteDialog, setConfirmQuoteDialog] = useState<boolean>(false);
  const {
    handleProcessPendingQuote,
    handleUploadPDFQuote,
    handleDeletePendingQuote,
  } = useAdminModelController(productRepository, quotesRepository);

  Font.register({
    family: "Open Sans",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      },
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
        fontWeight: 600,
      },
    ],
  });

  const handleChangeProductPrice = (productId: string, newPrice: string) => {
    const updatedPrices = newPrices.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          price: parseFloat(newPrice),
        };
      }
      return item;
    });

    setNewPrices(updatedPrices);
  };

  const onConfirm = async () => {
    setShowLoading(true);
    setConfirmQuoteDialog(false);
    const doc = QuoteComponent();
    const array: any = [];
    const asPdf = pdf(array);
    asPdf.updateContainer(doc);
    const pdfBlob: Blob = await asPdf.toBlob();

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10); // Formato data YYYY-MM-DD
    const formattedTime =
      currentDate.getHours() + "" + currentDate.getMinutes(); // Formato ora hhmm

    const fileName = `${quote.name}_${formattedDate}_${formattedTime}.pdf`;

    const pdfFile = new File([pdfBlob], fileName, {
      type: "application/pdf",
    });

    var pdfUrl = await handleUploadPDFQuote(quote.email, pdfFile, fileName);
    if (pdfUrl !== "") {
      let discount = parseFloat(discountValue);
      if (
        discount < 0 ||
        (discountType === DiscountType.Percentage && discount > 100) ||
        (discountType === DiscountType.Amount &&
          discount > parseFloat(calculateTotalWithTax()))
      ) {
        setShowLoading(false);
        showErrorToast("Inserisci un valore di sconto valido e riprova");
        return;
      }
      let processedQuote: NewProcessedQuote = {
        toEmail: quote.email,
        toName: quote.name,
        phone: quote.phone,
        pIVA: quote.pIVA,
        codUnivoco: quote.codUnivoco,
        codFiscale: quote.codFiscale,
        text: description,
        date: Date.now(),
        cart: newPrices,
        shippingPrice: parseFloat(shippingCost.toString()),
        shippingAddress: quote.shippingAddress,
        shippingCity: quote.shippingCity,
        shippingCap: quote.shippingCap,
        deliveryDate: quote.deliveryDate,
        deliveryTime: quote.deliveryTime,
        eventDate: quote.eventDate,
        returnDate: quote.returnDate,
        returnTime: quote.returnTime,
        localPickup: quote.localPickup,
        totalPrice: parseFloat(calculateTotalDiscounted().replace(",", ".")),
        pdfUrl: pdfUrl,
      };
      const html = await generateEmailContent(pdfUrl);
      let firebaseEmail: FirebaseEmail = {
        to: quote.email,
        message: {
          subject: "Il tuo preventivo di DapRent è pronto!",
          html: html,
        },
      };

      try {
        const result = await handleProcessPendingQuote(
          quote.id,
          processedQuote,
          firebaseEmail,
        );

        if (result) {
          setShowLoading(false);
          showSuccessToast("Il preventivo è stato confermato!");
          navigate("/dashboard/pendingQuotes");
        } else {
          setShowLoading(false);
          showErrorToast(
            "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
          );
        }
      } catch (error) {
        setShowLoading(false);
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
        );
      }
    } else {
      setShowLoading(false);
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
      );
    }
  };

  const calculateTotal = (): number => {
    let total = 0;

    newPrices.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });
    var shipping = shippingCost !== "" ? shippingCost : "0";
    total += parseInt(shipping);

    return total;
  };

  const calculateTax = (): string => {
    let total = 0;

    newPrices.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });
    var shipping = shippingCost !== "" ? shippingCost : "0";
    total += parseInt(shipping);

    return (total * 0.22).toFixed(2).replace(".", ",");
  };

  const onDelete = async () => {
    setShowLoading(true);
    try {
      const result = await handleDeletePendingQuote(quote.id);

      if (result) {
        setShowLoading(false);
        showSuccessToast("Il preventivo è stato eliminato!");
        navigate("/dashboard/pendingQuotes");
      } else {
        setShowLoading(false);
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
        );
      }
    } catch (error) {
      setShowLoading(false);
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi.",
      );
    }
  };

  const calculateTotalWithTax = (): string => {
    let total = 0;

    newPrices.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });
    var shipping = shippingCost !== "" ? shippingCost : "0";
    total += parseInt(shipping);

    let tax = total * 0.22;
    return (total + tax).toFixed(2).replace(".", ",");
  };

  const calculateTotalDiscounted = (): string => {
    var totalDiscounted = parseFloat(calculateTotalWithTax().replace(",", "."));
    var discountAmount =
      discountType === DiscountType.Percentage
        ? (totalDiscounted * parseFloat(discountValue)) / 100
        : parseFloat(discountValue);
    totalDiscounted -= discountAmount;
    return totalDiscounted.toFixed(2).replace(".", ",");
  };

  async function generateEmailContent(pdfUrl: string) {
    let emailHTML = `<div style="color:black; background-color:white;">`;
    emailHTML += `<p style="color:black;">${description.replace(
      /\n/g,
      "<br>",
    )}</p>`;
    emailHTML += `<br><div style="color:black;"><a href="${pdfUrl}" target="_blank">Visualizza il preventivo</a><br>`;
    return emailHTML;
  }

  const QuoteComponent = () => {
    const productsTotalPrice = calculateTotal();
    const taxes = calculateTax();
    const productsTotalPriceWithTax = calculateTotalWithTax();
    const totalDiscounted = calculateTotalDiscounted();

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.headerSection}>
              <View style={styles.companyInfo}>
                <Text style={styles.companyName}>Dap Rent</Text>
                <Text style={styles.companyData}>P.IVA: 03740990043</Text>
                <Text style={styles.companyData}>
                  Via Macallè, 33/A, 12045 Fossano CN
                </Text>
                <Text style={styles.companyData}>
                  daprent.preventivi@gmail.com | +39 347 468 9592
                </Text>
              </View>
              <Image src={logo} style={styles.logoHeader} />
            </View>

            <Text style={styles.quoteNumber}>
              Preventivo n° {quote.name.charAt(0).toUpperCase()}
              {new Date().getDate().toString().padStart(2, "0")}
              {String(new Date().getMonth() + 1).padStart(2, "0")}/
              {new Date().getFullYear().toString().slice(-2)}
            </Text>
            <View style={styles.clientRow}>
              <View style={styles.clientInfoContainer}>
                <Text style={styles.companyName}>Cliente</Text>
                <Text style={styles.companyData}>{quote.name}</Text>
                <Text style={styles.companyData}>
                  {quote.address}, {quote.cap}, {quote.city}
                </Text>
                <Text style={styles.companyData}>{quote.phone}</Text>
                <Text style={styles.companyData}>{quote.email}</Text>
                {quote.pIVA === "" ? (
                  <>
                    <Text style={styles.companyData}>{quote.pIVA}</Text>
                    <Text style={styles.companyData}>{quote.codUnivoco}</Text>
                  </>
                ) : (
                  <Text style={styles.companyData}>{quote.codFiscale}</Text>
                )}
              </View>
              <View style={styles.eventInfoContainer}>
                <Text style={styles.companyName}>Evento</Text>
                <Text style={styles.companyData}>
                  Spedizione:{" "}
                  {quote.localPickup ? "Ritiro in loco" : quote.shippingAddress}
                </Text>
                <Text style={styles.companyData}>
                  Data spedizione: {getFormattedDate(quote.deliveryDate)}{" "}
                  {quote.deliveryTime}
                </Text>
                <Text style={styles.companyData}>
                  Data evento: {getFormattedDate(quote.eventDate)}
                </Text>
                <Text style={styles.companyData}>
                  Data riconsegna: {getFormattedDate(quote.returnDate)}{" "}
                  {quote.returnTime}
                </Text>
              </View>
            </View>

            <View style={styles.tableHeader}>
              <Text style={[styles.tableCol]}>Immagine</Text>
              <Text style={styles.tableCol}>Prodotto</Text>
              <Text style={styles.tableCol}>Quantità</Text>
              <Text style={[styles.tableCol, styles.textRight]}>Prezzo</Text>
              <Text style={[styles.tableCol, styles.textRight]}>Importo</Text>
            </View>

            {newPrices.map((product) => (
              <View style={styles.tableRow} key={product.id}>
                <Image
                  style={[styles.tableCol, styles.imageCol]}
                  src={product.image}
                />
                <Text style={styles.tableCol}>{product.title}</Text>
                <Text style={styles.tableCol}>{product.quantity}</Text>
                <Text style={[styles.tableCol, styles.textRight]}>
                  €{product.price.toFixed(2)}
                </Text>
                <Text style={[styles.tableCol, styles.textRight]}>
                  €{(product.price * product.quantity).toFixed(2)}
                </Text>
              </View>
            ))}

            <View style={styles.summaryPriceSection}>
              <Text style={styles.summaryPriceContainer}>
                <Text style={styles.summaryLabel}>Subtotale: </Text>
                <Text style={styles.summaryValue}>
                  {productsTotalPrice.toFixed(2).replace(".", ",")}€
                </Text>
              </Text>

              <Text style={styles.summaryPriceContainer}>
                <Text style={styles.summaryLabel}>Costi di spedizione: </Text>
                <Text style={styles.summaryValue}>
                  {parseFloat(shippingCost).toFixed(2).replace(".", ",")}€
                </Text>
              </Text>
              <Text style={styles.summaryPriceContainer}>
                <Text style={styles.summaryLabel}>Totale IVA: </Text>
                <Text style={styles.summaryValue}>{taxes}€</Text>
              </Text>
              <Text style={styles.summaryPriceContainer}>
                <Text style={styles.summaryLabel}>Offerta cliente: </Text>
                <Text style={styles.summaryValue}>
                  {parseFloat(discountValue) > 0
                    ? totalDiscounted
                    : productsTotalPriceWithTax}
                  €
                </Text>
              </Text>
            </View>

            <Text style={styles.paymentInfo}>
              Per completare il pagamento, si prega di effettuare un bonifico
              all’IBAN IT91F0883346322000070102924, intestato a Eventi & Sapori
              Srl.
            </Text>

            <Text style={styles.footer}>
              Grazie per aver utilizzato il nostro servizio. Per ulteriori
              informazioni non esiti a contattarci telefonicamente al numero 333
              333 333 3333 o tramite email rispondendo a questo preventivo o
              inviandone una nuova all'indirizzo daprent.preventivi@gmail.com
            </Text>
            <Image src={logo} style={styles.logo} />

            <Text style={styles.title}>Condizioni Generali di Noleggio</Text>

            {contractItems.map((item, index) => (
              <Text style={styles.li} key={index}>
                {index + 1}) {item}
              </Text>
            ))}

            <Text style={styles.paymentInfo}>Luogo e data,</Text>
            <Text style={styles.paymentInfo}>__________________________</Text>
            <Text style={styles.paymentInfo}>Il Committente</Text>
            <Text style={styles.paymentInfo}>___________________________</Text>
            <Text style={styles.paymentInfo}>
              Ai sensi e per effetti degli artt. 1341 e 1342 c.c., il
              Committente dichiara di aver preso specifica visione e di
              approvare specificamente le seguenti clausole: Articolo 2 -
              Requisiti minimi di noleggio; Articolo 3 - Consegna e scarico del
              materiale; Articolo 4 - Garanzia e responsabilità del Committente;
              Articolo 5 - Riconsegna del materiale; Articolo 6 - Tardata
              riconsegna del materiale; Articolo 7 - Cotestazioni; Articolo 8 -
              Pagamenti; Articolo 9 - Caparra confirmatoria; Articolo 10 -
              Avvertenza; Articolo 11 - Clausola risolutiva; Articolo 12 -
              Cancellazioni; Articolo 13 - Variazioni; Articolo 14 - Legge
              applicapile e foro competente
            </Text>
            <Text style={styles.paymentInfo}>
              Letto, confermato e sottoscritto
            </Text>
            <Text style={styles.paymentInfo}>___________________________</Text>
          </View>
        </Page>
      </Document>
    );
  };

  if (showLoading) {
    return <LoaderView />;
  }
  return (
    <div className="min-h-full w-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <main className="mx-auto w-full px-6 md:px-24">
        <div className="my-4 justify-between border-b border-gray-200 md:my-10">
          <div className="my-3 flex">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Dettaglio preventivo
            </h1>
            <button
              type="button"
              className="ml-auto rounded-md bg-red-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-red-800"
              onClick={() => setConfirmDeleteDialog(true)}
            >
              Elimina preventivo
            </button>
          </div>
          <p className="mt-5 text-sm leading-5 text-gray-500">
            <span>{getFormattedDateWithHours(quote.date)}</span>
          </p>
          <h3 className="mt-2">
            <span className="font-bold">Cliente:</span> {quote.name}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Email: </span> {quote.email}
          </h3>
          {quote.codFiscale === "" ? (
            <div>
              <h3 className="mt-2">
                <span className="font-bold">Partita Iva:</span>{" "}
                {quote.pIVA !== "" ? quote.pIVA : "non specificata"}
              </h3>
              <h3 className="mt-2">
                <span className="font-bold">Codice univoco:</span>
                {quote.codUnivoco !== ""
                  ? quote.codUnivoco
                  : " non specificato"}
              </h3>
            </div>
          ) : (
            <h3 className="mt-2">
              <span className="font-bold">Codice fiscale:</span>{" "}
              {quote.codFiscale !== "" ? quote.codFiscale : " non specificato"}
            </h3>
          )}

          <h3 className="mt-2">
            <span className="font-bold">Indirizzo di spedizione:</span>{" "}
            {quote.shippingAddress} {quote.shippingCity} {quote.shippingCap}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data consegna:</span>{" "}
            {getFormattedDate(quote.deliveryDate)} {quote.deliveryTime ?? ""}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data evento:</span>{" "}
            {getFormattedDate(quote.eventDate)}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data ritiro:</span>{" "}
            {getFormattedDate(quote.returnDate)} {quote.returnTime ?? ""}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Ritiro in loco:</span>{" "}
            {quote.localPickup ? "Si" : "No"}
          </h3>

          <label
            htmlFor="cart"
            className="mt-10 block text-sm font-bold leading-6 text-gray-900"
          >
            Carrello
          </label>
          <div
            className="mt-6 w-full space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {quote.cart.length > 0 ? (
              quote.cart.map((product: ProductCart, index: number) => (
                <div
                  className="flex flex-col rounded-lg bg-white sm:flex-row"
                  key={product.id}
                >
                  <img
                    className="m-2 h-24 min-w-28 rounded-md border object-contain object-center"
                    src={product.image}
                    alt="product"
                  />
                  <div className="flex w-full flex-col px-4 py-4">
                    <span className="font-semibold">{product.title}</span>
                    <span className="float-right text-gray-400">
                      Quantità: {product.quantity}
                    </span>
                    <div className="flex space-x-3">
                      {editPrice ? (
                        <div className="">
                          <input
                            type="number"
                            name="price"
                            id="price"
                            value={newPrices[index].price}
                            onChange={(e) =>
                              handleChangeProductPrice(
                                product.id,
                                e.target.value,
                              )
                            }
                            className="w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                          />
                        </div>
                      ) : (
                        <p className="text-lg font-bold">
                          {newPrices[index].price.toFixed(2)}€
                        </p>
                      )}

                      {editPrice ? (
                        <button
                          aria-label="back-to-products"
                          onClick={() => setEditPrice(false)}
                        >
                          <FontAwesomeIcon
                            icon={faFloppyDisk}
                            className="inline-flex w-4"
                            style={{ color: "#97815C" }}
                          />
                        </button>
                      ) : (
                        <button
                          aria-label="back-to-products"
                          onClick={() => setEditPrice(true)}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="inline-flex w-4"
                            style={{ color: "#97815C" }}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="font-semibold">
                Non hai ancora aggiunto nessun prodotto al carrello
              </p>
            )}
          </div>
          <div className="col-span-full mt-10">
            <label
              htmlFor="description"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Testo della mail
            </label>
            <div className="mt-2">
              <textarea
                name="description"
                rows={3}
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="col-span-full mt-10 w-full">
            <label
              htmlFor="price"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Costi di spedizione
            </label>
            <div className="mt-2">
              <input
                type="number"
                name="price"
                id="price"
                value={shippingCost}
                placeholder="€"
                onChange={(e) =>
                  setShippingCost(e.target.value.replace(",", "."))
                }
                className="block w-[150px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
              />
            </div>
            <label
              htmlFor="price"
              className="mt-6 block text-sm font-medium leading-6 text-gray-900"
            >
              Sconto
            </label>
            <div className="relative mt-2 w-[150px] rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">
                  {discountType === DiscountType.Percentage ? "%" : "€"}
                </span>
              </div>
              <input
                type="number"
                name="discount"
                id="discountInput"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0"
                onChange={(e) =>
                  setDiscountValue(e.target.value.replace(",", "."))
                }
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <label htmlFor="currency" className="sr-only">
                  Sconto
                </label>
                <select
                  id="discountType"
                  name="discountType"
                  className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  onChange={(e) =>
                    setDiscountType(
                      e.target.value === "%"
                        ? DiscountType.Percentage
                        : DiscountType.Amount,
                    )
                  }
                >
                  <option>%</option>
                  <option>€</option>
                </select>
              </div>
            </div>
            <div className="mx-auto mt-10 rounded bg-neutral-100 p-4 lg:w-2/3">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Totale</p>

                <p className="text-sm font-semibold text-gray-900">
                  {calculateTotal()}€
                </p>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Iva 22%</p>
                <p className="text-sm font-semibold text-gray-900">
                  {calculateTax()}€
                </p>
              </div>
              <div className="mt-1 flex items-center justify-between">
                <p className="text-sm font-bold text-gray-900">
                  Totale inclusa iva
                </p>
                <p className="text-2xl font-semibold text-gray-900">
                  {calculateTotalWithTax()}€
                </p>
              </div>
              {parseFloat(discountValue) > 0 && (
                <>
                  <div className="mt-1 flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">Sconto</p>
                    <p className="text-sm font-semibold text-gray-900">
                      -
                      {discountType === DiscountType.Percentage
                        ? (
                            (parseFloat(calculateTotalWithTax()) *
                              parseFloat(discountValue.replace(",", "."))) /
                            100
                          )
                            .toFixed(2)
                            .replace(".", ",")
                        : parseFloat(discountValue)
                            .toFixed(2)
                            .replace(".", ",")}
                      €
                    </p>
                  </div>
                  <div className="mt-1 flex items-center justify-between">
                    <p className="text-sm font-bold text-gray-900">
                      Totale scontato
                    </p>
                    <p className="text-2xl font-semibold text-gray-900">
                      {calculateTotalDiscounted()}€
                    </p>
                  </div>{" "}
                </>
              )}
            </div>
          </div>
          <button
            type="button"
            className="mb-10 ml-auto mt-10 flex rounded-md bg-palette-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark"
            onClick={() => setConfirmQuoteDialog(true)}
          >
            Conferma preventivo
          </button>
        </div>
        <ConfirmDialogComponent
          type={DialogType.Delete}
          title="Elimina preventivo"
          body="Sei sicuro di voler eliminare questo preventivo? Questa azione non può essere annullata."
          open={confirmDeleteDialog}
          onClose={() => setConfirmDeleteDialog(false)}
          onConfirm={onDelete}
        />
        <ConfirmDialogComponent
          type={DialogType.Confirm}
          title="Conferma preventivo"
          body="Sei sicuro di voler confermare questo preventivo? Il cliente riceverà una email con il riepilogo dei prodotti e dei costi totali."
          open={confirmQuoteDialog}
          onClose={() => setConfirmQuoteDialog(false)}
          onConfirm={onConfirm}
        />
      </main>
      <FooterComponent />
    </div>
  );
}
