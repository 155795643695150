import { useEffect, useState } from "react";

const CustomTimePicker = ({
  onTimeSelect,
}: {
  onTimeSelect: (time: string) => void;
}) => {
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const times: string[] = [];
  for (let hour = 9; hour < 18; hour++) {
    times.push(`${hour.toString().padStart(2, "0")}:00`);
    times.push(`${hour.toString().padStart(2, "0")}:30`);
  }
  times.push("18:00");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest(".time-picker")) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <div className="time-picker">
      <input
        type="text"
        className="bg-white border-gray-300 border rounded-md text-gray-900 text-sm 
                  focus:ring-blue-500 focus:border-blue-500 block w-full p-2 cursor-pointer"
        placeholder="Seleziona un orario"
        value={selectedTime}
        readOnly
        onClick={() => setIsOpen(!isOpen)}
      />

      {isOpen && (
        <div
          className="absolute left-0 mt-2 w-full bg-white border border-gray-300 
                        shadow-md rounded-lg max-h-60 overflow-auto z-10"
        >
          {times.map((time) => (
            <div
              key={time}
              className="p-2 cursor-pointer hover:bg-gray-100 rounded-md"
              onClick={() => {
                setSelectedTime(time);
                onTimeSelect(time);
                setIsOpen(false);
              }}
            >
              {time}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomTimePicker;
