import logo from "../../assets/images/logo-dap-rent.png";

export function LoaderView() {
  return (
    <div className="fixed left-0 top-0 flex h-full w-full overflow-y-hidden bg-white">
      <img
        className="mx-auto my-auto h-[200px] animate-pulse"
        src={logo}
        alt="loader"
      />
    </div>
  );
}
